// @ts-nocheck
export default {
  BALANCE_SHEET: 'BALANCE_SHEET',
  TRIAL_BALANCE_SHEET: 'TRIAL_BALANCE_SHEET',
  JOURNAL: 'JOURNAL',
  GENERAL_LEDGER: 'GENERAL_LEDGER',
  PROFIT_LOSS: 'PROFIT_LOSS',
  AR_AGING_SUMMARY: 'AR_AGING_SUMMARY',
  AP_AGING_SUMMARY: 'AP_AGING_SUMMARY',
  DISPLAY_FILTER_DRAWER_TOGGLE: 'DISPLAY_FILTER_DRAWER_TOGGLE',
  PURCHASES_BY_ITEMS: 'PURCHASES_BY_ITEMS',
  SALES_BY_ITEMS: 'SALES_BY_ITEMS',
  INVENTORY_VALUATION: 'INVENTORY_VALUATION',
  CUSTOMERS_BALANCE_SUMMARY: 'CUSTOMERS BALANCE SUMMARY',
  VENDORS_BALANCE_SUMMARY: 'VENDORS BALANCE SUMMARY',
  CUSTOMERS_TRANSACTIONS: 'CUSTOMERS TRANSACTIONS',
  VENDORS_TRANSACTIONS: 'VENDORS TRANSACTIONS',
  CASH_FLOW_STATEMENT: 'CASH FLOW STATEMENT',
  INVENTORY_ITEM_DETAILS: 'INVENTORY ITEM DETAILS',
  PROJECT_PROFITABILITY_SUMMARY: 'PROJECT PROFITABILITY SUMMARY',
  REALIZED_GAIN_OR_LOSS: 'REALIZED GAIN OR LOSS',
  UNREALIZED_GAIN_OR_LOSS: 'UNREALIZED GAIN OR LOSS',
  SALES_TAX_LIABILITY_SUMMARY: 'SALES TAX LIABILITY SUMMARY',
};
